import { push, navigate } from "gatsby";
import { getStore } from "../Store";
import Actions from "../Reducers/Actions";
import ApiProxy from "../ApiProxy";
import Catchify from "../Utils/Catchify";
import API from "../Api";

export default dispatch => ({
  navActions: {
    push: Catchify(async (url, param) => {
      if (!param) {
        navigate(`/${url}`);
      } else {
        navigate(`/${url}`, param);
      }
    })
  },

  appActions: {
    setLang: Catchify(async lang => {
      dispatch({ type: Actions.SET_LANG, payload: lang });
    }),

    login: Catchify(
      async ({ username, password }) => {
        let response = await API.login({ username, password });
        ApiProxy.setToken(response.token);
        try {
          window.localStorage.setItem("token", response.token);
          window.localStorage.setItem("id", response.id);
        } catch (err) {
          console.log("cannot set localStorage! ");
        }
        dispatch({ type: Actions.LOGIN, payload: response });

        try {
          let cartResponse = await API.sls.getCart();
          dispatch({ type: Actions.SET_CART_DATA, payload: cartResponse });
          dispatch({
            type: Actions.SET_LOCAL_CART_DATA,
            payload: cartResponse
          });
        } catch (err) {
          console.warn("failed to get cart data!");
        }
      },

      async err => {
        ApiProxy.setToken(null);
        window.localStorage.removeItem("id");
        window.localStorage.removeItem("token");
        console.warn(err);
        throw err;
      }
    ),

    getMyProfile: Catchify(
      async () => {
        console.log("get my profile AC");
        let response = await API.getMyProfile();
        dispatch({ type: Actions.AUTO_LOGIN, payload: response });
      },

      async err => {
        console.warn(err);
        throw err;
      }
    ),

    autoLogin: Catchify(
      async () => {
        let token = window.localStorage.getItem("token");
        ApiProxy.setToken(token);
        if (token) {
          let response = await API.getMyProfile();
          dispatch({ type: Actions.AUTO_LOGIN, payload: response });
          try {
            let cartResponse = await API.sls.getCart();
            dispatch({ type: Actions.SET_CART_DATA, payload: cartResponse });
            dispatch({
              type: Actions.SET_LOCAL_CART_DATA,
              payload: cartResponse
            });
          } catch (err) {
            console.warn("failed to get cart data!");
          }
        }
      },

      async err => {
        console.warn(err);
        ApiProxy.setToken(null);
        window.localStorage.removeItem("token");
        throw err;
      }
    ),

    logout: Catchify(async () => {
      ApiProxy.setToken(null);
      window.localStorage.removeItem("id");
      window.localStorage.removeItem("token");
      dispatch({ type: Actions.LOGOUT, payload: null });
    }),

    register: async ({ username, email, password }) => {
      try {
        await API.register({ username, email, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetPassword: async ({ email }) => {
      try {
        await API.resetPassword({ email });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    resetValidation: async ({ username, password }) => {
      try {
        await API.resetValidation({ username, password });
        return Promise.resolve();
      } catch (err) {
        console.warn(err);
        return Promise.reject(err);
      }
    },

    editMyProfile: Catchify(
      async data => {
        let payload = await API.editMyProfile(data);
        dispatch({
          type: Actions.AUTO_LOGIN,
          payload
        });
        return payload;
      },
      err => Promise.reject(err)
    ),

    fetchProducts: async ({ limit, sort, offset, search, label }) => {
      try {
        let results = await API.fetchProducts({
          limit,
          sort,
          offset,
          search,
          label
        });
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchProductById: async id => {
      try {
        let result = await API.fetchProductById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchStoreById: async id => {
      try {
        let result = API.fetchStoreById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchProductsByStoreId: async id => {
      try {
        let result = API.fetchProductsByStoreId(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchArticles: async ({ limit, sort, offset, search, label }) => {
      try {
        let results = API.fetchArticles({ limit, sort, offset, search, label });
        return results;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    fetchArticleById: async id => {
      try {
        let result = API.fetchArticleById(id);
        return result;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    createOrder: Catchify(
      async data => {
        let result = await API.createOrder(data);
        return result;
      },
      err => Promise.reject(err)
    ),

    getOrderList: Catchify(
      async () => {
        let result = await API.getOrderList();
        return result;
      },
      err => Promise.reject(err)
    ),

    getOrder: Catchify(
      async id => {
        let result = await API.getOrder(id);
        return result;
      },
      err => Promise.reject(err)
    ),

    getCart: async dispatch => {
      let result = await API.sls.getCart();
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      dispatch({
        type: Actions.SET_LOCAL_CART_DATA,
        payload: result
      });
      return result;
    },

    editCartConfig: async config => {
      let result = await API.sls.editCartConfig(config);
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    editLocalCartConfig: config => {
      dispatch({
        type: Actions.SET_LOCAL_CART_CONFIG_DATA,
        payload: config
      });
    },

    addItemToCart: async ({ product, quantity }) => {
      let result = await API.sls.addItemToCart({ product, quantity });
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    removeItemFromCart: async index => {
      let result = await API.sls.removeItemFromCart(index);
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    editItemFromCart: async ({ index, config }) => {
      let result = await API.sls.editItemFromCart({ index, config });
      dispatch({
        type: Actions.SET_CART_DATA,
        payload: result
      });
      return result;
    },

    listOrders: async params => {
      try {
        return await API.sls.listOrders(params);
      } catch (ex) {
        console.warn(ex);
        throw ex;
      }
    }
  }
});
