// master branch should never touch this file!
const ApiProxy = require("../ApiProxy");
const constants = require("../Domain/constants");

const isAwsUser = constants.userType !== "mono";

const sls = {};
const local = {};

module.exports = {
  testApiProject: async () => {
    console.log("project api");
  },
  sls,
  local
};
