import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import AppConfig from "../../Config";
import OrderItem from "../OrderItem";

class OrderList extends Component {
  state = {
    orders: []
  };

  componentDidMount() {
    let { appActions } = this.props;
    appActions
      .getOrderList()
      .then(orders => this.setState({ orders }))
      .catch(err => 0);
  }
  render() {
    let { profile, navActions } = this.props;
    let { orders } = this.state;

    if (orders.length === 0) {
      return (
        <Wrapper>
          <div className="empty">
            <div className="text">
              <span className="highlight">{profile.name}</span>
              {"您好\n目前沒有任何訂單"}
            </div>
            <img src="" alt="icon" />
            <button onClick={() => navActions.push("/products")}>
              BUY NOW
            </button>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <div className="title">訂單記錄</div>
        {orders.map(o => (
          <OrderItem
            key={o.id}
            order={o}
            css={`
              margin-bottom: 10px;
            `}
          />
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .title {
    padding-bottom: 30px;
    font-size: 30px;
    color: #575757;
    border-bottom: 1px solid #e1e1e1;
  }

  & > .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    white-space: pre-wrap;
    text-align: center;

    & > .text {
      margin-bottom: 23px;
      letter-spacing: 1.25px;
      font-size: 20px;
      color: #575757;

      & > .highlight {
        margin: 0px 5px 18px 0px;
        color: gray;
      }
    }

    & > img {
      margin-bottom: 18px;
      width: 100px;
      height: 100px;
    }

    & > button {
      border: 0px;
      padding: 8px;

      cursor: pointer;
      min-width: 114px;
      background-color: gray;
      font-size: 14px;
      color: white;

      :focus {
        outline: none;
      }
    }
  }

  @media screen and (max-width: 780px) {
    max-width: 758px;
    padding: 50px;
    border: 1px solid #e1e1e1;
  }
  @media screen and (max-width: 450px) {
    max-width: 758px;
    padding: 20px;
    border: 1px solid #e1e1e1;
  }

  ${AppConfig["Components/OrderList"].css || ""}
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(OrderList);
