import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../ActionCreator";
import Form from "../Form";

const Wrapper = styled.div``;

class ProfileForm extends React.Component {
  render() {
    let { profile, appActions } = this.props;
    if (!profile) {
      return null;
    }

    return (
      <Wrapper>
        <Form
          model={{
            fields: [
              { name: "name", type: "text", label: "Name" },
              { name: "phone", type: "text", label: "Phone" },
              { name: "address", type: "textarea", label: "Address" },
              {
                name: "gender",
                type: "select",
                label: "Gender",
                choices: [
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                  { label: "None", value: "none" }
                ]
              },
              {
                name: "extra",
                type: "select-ext",
                label: "Extra",
                extra: {
                  fnFetchChoices: async () => [
                    { id: 1, name: "AAA" },
                    { id: 2, name: "BBB" },
                    { id: 3, name: "CCC" }
                  ],
                  fnGetLabel: i => i.name
                }
              },
              { name: "about_me_doc", type: "file", label: "About Me" },
              { name: "picture", type: "image", label: "Picture" },
              {
                name: "allow_promotion",
                type: "checkbox",
                label: "Do you want to receive promotion email? "
              },
              { name: "data.special1", type: "text", label: "Special 1" },
              { name: "data.special2", type: "text", label: "Special 2" },
              { name: "data.special3", type: "checkbox", label: "Special 3" }
            ]
          }}
          instance={profile}
          fnUpdate={appActions.editMyProfile}
        />
      </Wrapper>
    );
  }
}

export default connect(
  null,
  ActionCreator
)(ProfileForm);
