import React from "react";
import styled from "styled-components";
import InputField from "../InputField";

const Wrapper = styled.div``;

class Form extends React.Component {
  //--------------------------------------------------------------
  //  [model definition example]
  //  model: {
  //    fields: [
  //      { name: 'name', type: 'text', label: 'Name', },
  //      { name: 'address', type: 'textarea', label: 'Address', },
  //      { name: 'confirm', type: 'checkbox', label: 'Confirm', },
  //      { name: 'type', type: 'select', label: 'Type', choices: [{label: 'A', value: 'a'}, {label: 'B', value: 'b'}]},
  //      { name: "file", type: "file", label: "File Upload" },
  //      { name: "image", type: "image", label: "Image Upload" },
  //      { name: 'owner', type: "select-ext", label: 'Owner',
  //        extra: {
  //          fnFetchChoices: () => Promise.resolve([{label: 'A', value: 'a'}, {label: 'B', value: 'b'}]),
  //          fnGetLabel: (choice) => choice.label
  //        }
  //      },
  //    ]
  //  }
  //--------------------------------------------------------------

  constructor(props) {
    super(props);
    this.state = {
      values: (this.props.instance && { ...this.props.instance }) || {},
      updating: false
    };
  }

  render() {
    let { model } = this.props;
    let { values, updating } = this.state;

    let fieldProps = {
      obj: values,
      objName: "values",
      setState: this.setState.bind(this),
      disabled: updating
    };

    return (
      <Wrapper>
        <div>
          {model.fields.map(field => {
            return (
              <InputField
                key={field.name}
                name={field.name}
                type={field.type}
                label={field.label}
                value={_extractValue({ field, values })}
                choices={field.choices}
                extra={field.extra ? field.extra : {}}
                {...fieldProps}
              />
            );
          })}
        </div>

        <button onClick={this._submit}>Submit</button>

        {updating && <h4>Updating...</h4>}
      </Wrapper>
    );
  }

  _submit = async () => {
    let { model, instance, fnCreate, fnUpdate } = this.props;
    let { values } = this.state;

    for (let field of model.fields) {
      if (field.required) {
        switch (field.type) {
          case "text":
            if (!values[field.name]) {
              return;
            }
          default:
            break;
        }
      }
    }

    let nextInstance = null;

    this.setState({ updating: true });

    try {
      if (!instance) {
        // Create
        nextInstance = await fnCreate(values);
      } else {
        // Update
        nextInstance = await fnUpdate(values);
      }
    } catch (ex) {
      console.warn(ex);
    }

    this.setState({ instance: nextInstance, updating: false });
  };
}

const _extractValue = ({ field, values }) => {
  let partsOfName = field.name.split(".");
  if (partsOfName.length === 1) {
    return values[field.name];
  } else if (partsOfName.length === 2) {
    let [first, second] = partsOfName;
    let data = values[first];
    try {
      if (typeof data === "string") {
        data = JSON.parse(data);
      }
    } catch (ex) {
      data = {};
    }
    return data[second];
  } else {
    throw "[rev-cms][Form] currently only support 2 level deep!";
  }
};

export default Form;
