import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";

class Profile extends Component {
  render() {
    let { profile } = this.props;
    return (
      <Wrapper avatar={profile.avatar || profile.social_avatar}>
        <div className="avatar" />
        <div className="info">
          User Name: {profile.name || profile.user.username}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .avatar {
    background-color: grey;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: url(${props => props.avatar});
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(Profile);
