const fetchImpl = (() => {
  try {
    return fetch;
  } catch (ex) {}
  return require("node-fetch");
})();

exports.docClientFactory = ({ url }) => {
  const rpc = async (
    method,
    params,
    options = {
      token: null
    }
  ) => {
    let fullUrl = url;
    if (options.token) {
      fullUrl = `${url}?token=${options.token}`;
      if (options.token_type) {
        fullUrl = `${fullUrl}&token_type=${options.token_type}`;
      }
    }

    return fetchImpl(fullUrl, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ method, params })
    }).then(resp => {
      if (resp.status.toString()[0] !== "2") {
        throw resp;
      }
      return resp.json();
    });
  };

  return {
    // TODO: curry these..
    put: async (params, options) => rpc("put", params, options),
    get: async (params, options) => rpc("get", params, options),
    query: async (params, options) => rpc("query", params, options),
    describeTable: async (params, options) =>
      rpc("describeTable", params, options)
  };
};
