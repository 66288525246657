import * as _User from "../Reducers/User";
import * as _Lang from "../Reducers/Lang";
import * as _Product from "../Reducers/Product";
import * as _Cart from "../Reducers/Cart";
import * as _LocalCart from "../Reducers/LocalCart";
import * as _Store from "../Reducers/Store";
import { namedExportWithMemorizedOnce } from "../Utils/FpUtil";
import ParseQuery from "../Utils/ParseQuery";

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let Product = namedExportWithMemorizedOnce(_Product);
let Cart = namedExportWithMemorizedOnce(_Cart);
let LocalCart = namedExportWithMemorizedOnce(_LocalCart);
let Store = namedExportWithMemorizedOnce(_Store);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
  getProduct: (state, id) => Product.byId(state.product, id),
  getProductList: state => Product.list(state.product),
  getCartData: state => Cart.getCartData(state.cart),
  getLocalCartData: state => LocalCart.getLocalCartData(state.localCart),
  getLocalCartDataConfig: state =>
    LocalCart.getLocalCartDataConfig(state.localCart),
  getStoreList: state => Store.list(state.store)
};

export default selectors;
