import React, { Component } from "react";
import styled from "styled-components";
import Constants from "../../Domain/constants";

const startAsHttpPattern = /^(http|https):\/\//;

class Image extends Component {
  render() {
    let { src, host, css, background } = this.props;
    let Comp = background ? Background : Img;
    return (
      <Comp
        css={css}
        onLoad={this._imgOnLoad}
        onError={this._imgOnError}
        {...this.props}
        src={this._getFullImageUrl(src, host)}
      />
    );
  }

  _imgOnLoad = e => {};

  _imgOnError = e => {
    let { src } = this.props;
    if (!src.match(startAsHttpPattern)) {
      console.log("on error!! try next host...");
    }
  };

  _getFullImageUrl = (src, host) => {
    if (!src) {
      return "";
    }
    if (src.match(startAsHttpPattern)) {
      return src;
    } else if (src.indexOf("/") === 0) {
      return `${host}${src}`;
    } else if (src.indexOf(".") === 0) {
      return src;
    } else {
      return `${host}/${src}`;
    }
  };
}

Image.defaultProps = {
  host: Constants.mediaHost,
  background: false,
  css: ""
};

const Img = styled.img`
  ${props => props.css}
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  ${props => props.css}
`;

export default Image;
