export const SupportLangs = [
  { display: "繁體中文", key: "tw" },
  { display: "English", key: "en" }
];

export const DefaultLang = "tw";

const StringTable = {
  tw: {
    welcome: "歡迎!",
    cancel: "取消",
    "all-fields-required": "所有欄位皆須填寫",
    register: "註冊新帳號",
    login: "登入",
    logout: "登出",
    "log-in-with-facebook": "Facebook 登入",
    "log-in-with-google": "Google 登入",
    username: "帳號",
    email: "電子信箱",
    password: "密碼",
    "must-have-username-and-password": "帳號及密碼為必填!",
    "error-login-message": "帳號密碼錯誤!",
    "username-used": "此帳號已被使用！",
    "email-used": "此電子信箱已被使用！",
    "error-register-message": "註冊失敗！",
    "error-message": "發生錯誤",
    "already-member": "已經有帳號了？",
    "not-member": "還沒有帳號？",
    "forget-password": "忘記密碼？",
    "email-not-found": "此電子信箱不存在！",
    "error-reset-password-message": "重設密碼失敗！",
    "reset-password": "重設密碼",
    "next-step": "下一步",
    "back-to": "回到",
    "reset-password-success-message": "新密碼已寄至您的電子信箱。",
    "reset-validation": "重寄認證信",
    "reset-validation-message":
      "您的帳號已註冊成功，\n但尚未通過認證。\n請收取認證信並點選連結。",
    "reset-validation-success-message": "認證信已寄出！",
    "err-reset-validation-message": "寄件失敗！",
    "payment-success": "已付款",
    "payment-failure": "付款失敗",
    "payment-waiting": "尚未付款",
    "payment-processing": "付款中",
    "payment-type-ecpay": "線上刷卡",
    "payment-type-paypal": "PAYPAL",
    "payment-type-offline": "銀行轉帳",
    "login-first": "請先登入！",
    "no-order-or-no-right-access-order": "無此訂單或您無權限瀏覽此訂單"
  },
  en: {
    welcome: "Welcome!",
    cancel: "Cancel",
    "all-fields-required": "All fields are required.",
    register: "Register",
    login: "Login",
    logout: "Logout",
    "log-in-with-facebook": "Log in with Facebook",
    "log-in-with-google": "Log in with Google",
    username: "Username",
    email: "Email",
    password: "Password",
    "must-have-username-and-password":
      "Please enter both username and password!",
    "error-login-message": "Fail to login!",
    "error-register-message": "Fail to register!",
    "username-used": "The username is already used!",
    "email-used": "The email is already used!",
    "error-message": "Error happen",
    "already-member": "Already a member?",
    "not-member": "Not a member?",
    "forget-password": "Forgot Password?",
    "email-not-found": "Can't find this email.",
    "error-reset-password-message": "Fail to reset password!",
    "reset-password": "Reset Password",
    "back-to": "Back to",
    "reset-password-success-message":
      "Your new password has been sent to your email.",
    "reset-validation": "Resend Validation Email",
    "reset-validation-message":
      "Your account is registered but not validated.\nPlease receive the validation email and click the link.",
    "reset-validation-success-message": "The validation email is sent!",
    "err-reset-validation-message": "Fail to send email!",
    "payment-success": "Success",
    "payment-failure": "Failure",
    "payment-waiting": "Waiting",
    "payment-processing": "Processing",
    "payment-type-ecpay": "Credit Card",
    "payment-type-paypal": "PAYPAL",
    "payment-type-offline": "ATM",
    "login-first": "Please login first!",
    "no-order-or-no-right-access-order":
      "This order does not exist or you have no right to access this order."
  }
};

export default StringTable;
