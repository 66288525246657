import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import * as Widget from "../../Components/Widget";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import Login from "../../Components/Login";
import Profile from "../../Components/Profile";
import ProfileForm from "../../Components/ProfileForm";
import OrderList from "../../Components/OrderList";

const TABS = {
  INFO: "INFO",
  ORDERS: "ORDERS"
};

class ProfilePage extends React.Component {
  state = {
    activeTab: TABS.INFO
  };

  render() {
    let { appActions, navActions, profile, totalItemsInCart } = this.props;
    let { activeTab } = this.state;

    if (!profile) {
      return (
        <Widget.MaxWidth width={800} extraCss="padding: 20px">
          <Widget.Center>
            <Login.Button />
          </Widget.Center>
        </Widget.MaxWidth>
      );
    }

    return (
      <Wrapper>
        <div className="container">
          <div className="user">
            <Profile />
          </div>
          <div className="tabs-header">
            <div
              className={`tab${activeTab === TABS.INFO ? " active" : ""}`}
              onClick={() => this.setState({ activeTab: TABS.INFO })}
            >
              個人資訊
            </div>
            <div className={`tab`} onClick={() => navActions.push("/cart")}>
              購物車({totalItemsInCart})
            </div>
            <div
              className={`tab${activeTab === TABS.ORDERS ? " active" : ""}`}
              onClick={() => this.setState({ activeTab: TABS.ORDERS })}
            >
              訂單記錄
            </div>
          </div>
          <div className="tabs-content">
            {activeTab === TABS.INFO ? (
              <ProfileForm profile={profile} />
            ) : (
              <OrderList />
            )}
          </div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;

    & > .tabs-header {
      display: flex;
      align-items: center;
      justify-content: center;

      & > .tab {
        padding: 10px 15px;
        cursor: pointer;
      }

      & > .tab.active {
        background-color: lightblue;
      }
    }

    & > .tabs-content {
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      totalItemsInCart:
        (Selectors.getCartData(state) &&
          Selectors.getCartData(state).items.length) ||
        0
    }),
    ActionCreator
  )(ProfilePage)
);
